<template>
    <div>
        <div class="global-container">
        <div class="global-info">
          <h2 class="text-center">HELPING YOU CONNECT WITH THE WORLD</h2>
        </div>
        <div class="global-flex-box-holder">
          <div class="global-map-holder">
              <iframe src="https://www.google.com/maps/d/embed?mid=1eKaaUu_O8Nf0qT3h1QiimzAWMRXK1hmV" width="100%" height="480"></iframe>
            </div>
        </div>
        <div class="global-flex-box-holder">
            <div class="global-card" v-for="(country) in countries" :key="country.id">

              <div class="">
                <h3 class="mar-top-15 global-head">{{country.name}}</h3>
                <div>
                    <ul class="horizontal-ul">
                        <li v-for="(company) in country.companies" :key="company.id">
                            <span v-if="!company.link">{{company.name}}</span>
                            <a target="_blank" v-else :href="`${company.link}`" >{{company.name}}</a>
                        </li>
                    </ul>
                </div>
              </div>
              
            </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Global",
  data: ()=>({
      countries:[
          {
              id:1,
              name: "USA",
              companies:[
                  {
                      id:1,
                      name:'Ploutus Holdings LLC',
                      link:'https://ploutus.com/'
                  },
                  {
                      id:2,
                      name:'Transform Associates Inc.',
                      link:'http://www.transformassociatesinc.com/'
                  },
                  {
                      id:3,
                      name:'Caelo Communications',
                      link:'http://www.caelocommunications.com/'
                  },
                  {
                      id:4,
                      name:'Baily Tea',
                      link:'https://www.bailytea.com/'
                  },
                  {
                      id:5,
                      name:'Young Enterprise System Inc.',
                      link:'http://yesys.com/'
                  },
                  {
                      id:6,
                      name:'Boussard Oil & Gas LLC',
                      link:'https://www.boussardusa.com/'
                  },
                  {
                      id:7,
                      name:'ADP Consultants Inc.',
                      link:'https://adpconsultantsinc.com/'
                  },
                  {
                      id:8,
                      name:'ADP Construction',
                      link:'https://adpconstructioninc.com/'
                  },
                  {
                      id:9,
                      name:'Sun Resources USA',
                      link:''
                  },
                  {
                      id:10,
                      name:'Roma LLC',
                      link:''
                  },
                  {
                      id:11,
                      name:'Ploutus Oil & Gas LLC',
                      link:''
                  },
                  {
                      id:12,
                      name:'Transform Energy Services LLC',
                      link:''
                  },
                  {
                      id:13,
                      name:'Ploutus Distributors LLC',
                      link:''
                  },
                  {
                      id:14,
                      name:'Ploutus Motors LLC',
                      link:''
                  },
              ]
          },
          {
              id:2,
              name: "UAE",
              companies:[
                  {
                      id:1,
                      name:'Ploutus International Investments LLC',
                      link:'https://ploutus.ae/'
                  },
                  {
                      id:2,
                      name:'Ploutus Aviation LLC',
                      link:'https://ploutusaviation.com/'
                  },
                  {
                      id:3,
                      name:'Goldway Precious Metal Trading LLC',
                      link:'http://www.goldway.ae/'
                  },
                  {
                      id:4,
                      name:'Ploutus LLC',
                      link:'https://ploutus.ae/'
                  },
                  {
                      id:5,
                      name:'Ploutus Trading LLC',
                      link:'https://ploutus.ae/'
                  },
                  {
                      id:6,
                      name:'Himat Gold LLC',
                      link:''
                  },
                  {
                      id:7,
                      name:'Ploutus Import & Export LLC',
                      link:''
                  },
                  {
                      id:8,
                      name:'Ploutus Entertainment LLC',
                      link:''
                  },
                  {
                      id:9,
                      name:'Ploutus Restaurants LLC',
                      link:''
                  },
                  {
                      id:10,
                      name:'Global ComFin Investments LLC',
                      link:''
                  },
                  {
                      id:11,
                      name:'Transfrom Consultants FTZ',
                      link:''
                  },
              ]
          },
          {
              id:3,
              name: "Sri Lanka",
              companies:[
                  {
                      id:1,
                      name:'Ploutus Holdings (Sri Lanka) LLC',
                      link:''
                  },
                  {
                      id:2,
                      name:'Euroasia Argo (Pvt) Ltd',
                      link:''
                  },
                  {
                      id:3,
                      name:'Baily Distributors (Sri Lanka) LLC',
                      link:''
                  },
              ]
          },
          {
              id:4,
              name: "Ghana",
              companies:[
                  {
                      id:1,
                      name:'Ploutus (Ghana) LLC',
                      link:''
                  },
                  {
                      id:2,
                      name:'Ploutus General Trading LLC',
                      link:''
                  },
                  {
                      id:3,
                      name:'First Elements Trading LLC',
                      link:''
                  },
              ]
          },
          {
              id:5,
              name: "Kenya",
              companies:[
                  {
                      id:1,
                      name:'Ploutus Holdings (Kenya) Limited',
                      link:'https://kenya.ploutus.com/'
                  }
              ]
          },
          {
              id:6,
              name: "England",
              companies:[
                  {
                      id:1,
                      name:'Ploutus UK Limited',
                      link:''
                  }
              ]
          },
          {
              id:7,
              name: "India",
              companies:[
                  {
                      id:1,
                      name:'Ploutus Distributors LLC',
                      link:''
                  },
                  {
                      id:2,
                      name:'Assistive Health Pvt Ltd',
                      link:''
                  },
              ]
          },
          {
              id:8,
              name: "Brazil",
              companies:[
                  {
                      id:1,
                      name:'Ploutus Grains Brazil LLC',
                      link:''
                  }
              ]
          },
          {
              id:9,
              name: "Tanzania",
              companies:[
                  {
                      id:1,
                      name:'Ploutus Solutions TZ Limited',
                      link:''
                  }
              ]
          },
          {
              id:10,
              name: "Uganda",
              companies:[
                  {
                      id:1,
                      name:'Ploutus Universal Company Ltd',
                      link:''
                  }
              ]
          },
          {
              id:11,
              name: "Nigeria",
              companies:[
                  {
                      id:1,
                      name:'Ploutus Oil & Gas (Nigeria) Ltd',
                      link:''
                  }
              ]
          },
          {
              id:12,
              name: "Sierra Leone",
              companies:[
                  {
                      id:1,
                      name:'Ploutus Universal Company Ltd',
                      link:''
                  }
              ]
          },
      ]
  }),

};
</script>
<style>

.global-container{
  max-width: 1200px;
  margin: 0 auto;
}

.global-info{
  padding: 20px;
  margin: 20px;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.global-info::before, .global-map-holder::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 15px;
}

.global-flex-box-holder{
  flex-wrap: wrap;
}

.global-card {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    padding: 15px;
}

.global-head{
    font-size: 25px;
    font-weight: 800;
}

.v-application ul.horizontal-ul{
    list-style-type: none;
    padding-left: 0;
}

.horizontal-ul li{
    display: inline-block;
    padding: 10px 10px 10px 0;
    margin: 5px 5px 5px 0;
    border-radius: 15px;
    color: #0a0a0a;
    font-weight: 500;
}

.horizontal-ul li a{
    text-decoration: none;
    color:#4d90fe;
}

.global-map-holder{
  padding: 20px;
  position: relative;
  transition: all 0.5s;
  width: 100%;
  position: relative;
  border-radius: 15px;
}

</style>