<template>
  <div>
    <div class="csr-container">
      <div class="csr-info">
        <h2 class="text-center">{{title}}</h2>
      </div>
      <div class="csr-flex-box-holder">
        <div class="csr-card" v-for="(data) in mainData" :key="data.id">
          <div class="">
            <p class="mar-top-15">{{data.details}}</p>
            <div>
              <div class="text-center">
                <v-chip
                  class="ma-2"
                  color="ploBlue"
                  text-color="ploYellow"
                  v-for="(chip) in data.chips" :key="chip.id"
                >
                  <v-avatar left>
                    <v-icon>{{chip.icon}}</v-icon>
                  </v-avatar>
                  {{chip.name}}
                </v-chip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="csr-info">
        <h3 class="text-center">“Life’s most persistent and urgent question is, What are you doing for others?”</h3>
        <h4 class="mar-top-15 text-right">Martin Luther King, Jr.</h4>
      </div>
      <div class="csr-info">
        <h2 class="text-center">{{title2}}</h2>
        <p class="mar-top-15">{{details2}}</p>
      </div>
      <div class="csr-info">
        <h2 class="text-center">OUR CSR PROJECTS</h2>
      </div>
      <div class="csr-info">
        <div class="csr-img-container">
        <div
            v-for="(image) in images"
            :key="image.id"
            class="box"
          >
            <v-img
              :src="require(`../assets/${image.link}`)"
              :lazy-src="require(`../assets/${image.link}`)"
              aspect-ratio="1"
              class="grey lighten-2"
              width="100%"
              height="100%"
            >
            <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-btn
                color="primary"
                dark
                @click.stop="image.dialog = true"
                class="img-btn"
              >
                Click
              </v-btn>
              <v-dialog
                v-model="image.dialog"
                max-width="600"
              >
                <img :src="require(`../assets/${image.link}`)" class="responsive-image"  :alt="`${image.link}`">

              </v-dialog>
            </v-img>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Csr",
  data: ()=>({
    title:"WE BELIEVE IN CHANGE",
    mainData:[
      {
        id:1,
        details:"At Ploutus Holdings, LLC our entire csr team is passionate about making a positive difference in the communities that we live and operate in. From Accra to Mumbai, we are committed to positively impacting the lives of those that we come across. Some of the causes that we care about include:",
        chips:[
          {
            id:1,
            name:"Children’s Education & Nutrition",
            icon:"mdi-library",
          },
          {
            id:2,
            name:"Women Empowerment",
            icon:"mdi-human-female",
          },
          {
            id:3,
            name:"Breast Cancer Awareness",
            icon:"mdi-ribbon",
          },
          {
            id:4,
            name:"Clean Water Supply",
            icon:"mdi-hand-water",
          },
          {
            id:5,
            name:"Construction of Low-Cost Housing",
            icon:"mdi-bulldozer",
          },
          {
            id:6,
            name:"Health Care for the Disabled & Elderly",
            icon:"mdi-human-wheelchair",
          },
        ]
      }
    ],
    title2:"HELPING OUR CSR COMMUNITY",
    details2:"At Ploutus, we care deeply about creating sustainable changes around us. We take pride in our team and are constantly inspired by them to do our part to help our csr community.",
    images:[
      {
        id:1,
        link:"csr1.jpg",
        dialog:false,
      },
      {
        id:2,
        link:"csr2.jpg",
        dialog:false,
      },
      {
        id:3,
        link:"csr3.jpg",
        dialog:false,
      },
      {
        id:4,
        link:"csr4.jpg",
        dialog:false,
      },
      {
        id:5,
        link:"csr5.jpg",
        dialog:false,
      },
      {
        id:6,
        link:"csr6.jpg",
        dialog:false,
      },
      {
        id:7,
        link:"csr7.jpg",
        dialog:false,
      },
      {
        id:8,
        link:"csr8.jpg",
        dialog:false,
      },
      {
        id:9,
        link:"csr9.jpg",
        dialog:false,
      },
      {
        id:10,
        link:"csr10.jpg",
        dialog:false,
      },
      {
        id:11,
        link:"csr11.jpg",
        dialog:false,
      },
      {
        id:12,
        link:"csr12.jpg",
        dialog:false,
      },
      {
        id:13,
        link:"csr13.jpg",
        dialog:false,
      },
    ]
  }),

};
</script>
<style>

.csr-container{
  max-width: 1200px;
  margin: 0 auto;
}

.csr-info{
  padding: 20px;
  margin: 20px;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  border-radius: 15px;
}

.csr-info::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 15px;
}

.csr-flex-box-holder{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.csr-card {
    position: relative;
    margin: 30px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    padding: 15px;
}

.csr-img-container{
  position: relative;
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: minmax(100px, auto);
  grid-auto-flow: dense;
}

.csr-img-container .box{
  display: grid;
  font-size: 20px;
  place-items: center;
  color:#fff;
  transition: 0.5s;
  position: relative;
  transition: all 0.5s;
  border: 1px solid #fab200;
}

.csr-img-container .box:nth-child(1){
  grid-column: span 2;
  grid-row: span 1;
}
.csr-img-container .box:nth-child(2){
  grid-column: span 1;
  grid-row: span 2;
}
.csr-img-container .box:nth-child(3){
  grid-column: span 2;
  grid-row: span 1;
}
.csr-img-container .box:nth-child(4){
  grid-column: span 2;
  grid-row: span 2;
}
.csr-img-container .box:nth-child(5){
  grid-column: span 2;
  grid-row: span 1;
}
.csr-img-container .box:nth-child(6){
  grid-column: span 2;
  grid-row: span 1;
}
.csr-img-container .box:nth-child(7){
  grid-column: span 2;
  grid-row: span 2;
}
.csr-img-container .box:nth-child(8){
  grid-column: span 1;
  grid-row: span 2;
}
.csr-img-container .box:nth-child(9){
  grid-column: span 2;
  grid-row: span 1;
}
.csr-img-container .box:nth-child(10){
  grid-column: span 3;
  grid-row: span 1;
}
.csr-img-container .box:nth-child(11){
  grid-column: span 2;
  grid-row: span 1;
}
.csr-img-container .box:nth-child(12){
  grid-column: span 3;
  grid-row: span 2;
}
.csr-img-container .box:nth-child(13){
  grid-column: span 2;
  grid-row: span 1;
}

.responsive-image{
  width: 100%;
}

.img-btn{
    width: 100%;
    height: 100% !important;
    opacity: 0.1;
}

@media screen and (max-width:1015px) {
  .csr-img-container{
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-template-rows: minmax(auto, auto);
  }

  .menu-img-container .box{
    grid-column: unset !important;
    grid-row: unset !important;
  }

}

</style>