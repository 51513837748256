<template>
    <v-app class="">
    <!-- <v-app-bar
      app
      color="primary"
      dark
    >
    </v-app-bar> -->
    <Navbar v-bind:drawer="drawer" @changedrawer="changeDrawer"/>
    <v-main class=" main-bg">
      <main class="">
        <router-view v-on:updateDrawer="changeDrawer($event)"></router-view>
      </main>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'App',
  components: {Navbar},

  data() {
    return {
      drawer: false
    }
  },
  methods:{
    changeDrawer:function(drawz){
      this.drawer = drawz;
    }
  },
  // computed: {
  //   reversedDraw() {
  //     return this.drawer;
  //   }
  // }
};
</script>

<style>

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FAB600; 
}

/* ::-webkit-scrollbar-track:hover {
  background: #fff; 
} */
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #213880; 
}


@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

body .v-application{
  font-family: 'Roboto Condensed', sans-serif;
}
.main-bg {
  background: url('~@/assets/bg.webp') no-repeat bottom right fixed;
    background-size: contain;
    box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 5%);
}

main h2, p{
  color: #213880;
}
</style>
