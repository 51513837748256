<template>
    <div>
      <div class="contact-container">
        <div class="contact-info">
          <h2 class="text-center">Get In Touch</h2>
        </div>
        <div class="contact-flex-box-holder">
            <div class="contact-card" v-for="(card) in cards" :key="card.id">

              <div class="contact-icon-holder">
                <v-icon color="ploBlue" x-large class="contact-icon">{{card.icon}}</v-icon>
              </div>
              <div class="text-center">
                <h3 class="mar-top-15">{{card.heading}}</h3>
                <h5 class="">{{card.details}}</h5>
              </div>
              
            </div>
        </div>
        <div class="contact-flex-box-holder">
          <div class="map-holder"><iframe style="border: 0;" tabindex="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12384.74741383876!2d-77.1818562!3d39.1022159!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdf5c009facfe93fb!2sPloutus%20Holdings%20LLC!5e0!3m2!1sen!2sae!4v1613655842577!5m2!1sen!2sae" width="300" height="350" frameborder="0" allowfullscreen="allowfullscreen" aria-hidden="false"></iframe></div>
          <div class="map-holder"><iframe style="border: 0;" tabindex="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3105.3078779313705!2d-77.0327500846908!3d38.894074279571164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b65bdab49ea7%3A0x41341f7a7c7979e!2s1300%20Pennsylvania%20Avenue%20NW%2C%20Washington%2C%20DC%2020004%2C%20USA!5e0!3m2!1sen!2sae!4v1613651219991!5m2!1sen!2sae" width="300" height="350" frameborder="0" allowfullscreen="allowfullscreen" aria-hidden="false"></iframe></div>
        </div>
      </div>

    </div>
</template>

<style>
.contact-container{
  max-width: 1200px;
  margin: 0 auto;
}

.contact-info{
  padding: 20px;
  margin: 20px;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.contact-info::before, .map-holder::before{
  content: '';
  position: absolute;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 15px;
}

.contact-flex-box-holder{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.contact-card {
    position: relative;
    width: 250px;
    margin: 30px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px;
    align-items: center;
}

.contact-icon-holder{
  margin: 10px auto;
}

.contact-icon{
  background-color: #FAB600;
  border-radius: 50px;
  padding: 10px;
}

.mar-top-15{
  margin-top: 15px;
}

.map-holder{
  padding: 20px;
  margin: 20px;
  position: relative;
  transition: all 0.5s;
}


</style>

<script>
export default {
  name: "Contact",
  data(){
    return{
      cards:[
        {
          id:1,
          icon:"mdi-map-marker",
          heading:"",
          details:"2275 Research Blvd, Rockville, MD 20850, USA"
        },
        {
          id:2,
          icon:"mdi-map-marker",
          heading:"",
          details:"1300 Pennsylvania Avenue NW, Suite 700 Washington, DC 20004, USA"
        },
        {
          id:3,
          icon:"mdi-phone-in-talk",
          heading:"",
          details:"+1 240 731 3911"
        },
        {
          id:4,
          icon:"mdi-email",
          heading:"",
          details:"info@ploutusholdings.com"
        },
      ]
    }
  },
  mounted(){

    }

};
</script>